<template>
  <div class="active--switch">
    <label :for="`publishToggle${listingUuid}`" class="custom--switch">
      <input
        :id="`publishToggle${listingUuid}`"
        type="checkbox"
        :checked="false"
        @click.prevent="inputHandler"
      />
      <span class="slider"></span>
    </label>
    <label :for="`publishToggle${listingUuid}`" class="switch--label">
      {{ $t('general.btn.notPublish') }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'publish-toggle',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
    checkBankAccountDone: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: null,
    },
    fromMylisting: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
    bankAccountCheckPayload: {
      get() {
        return this.$store.state.v2.profile.bankAccountCheckPayload;
      },
      set(val) {
        this.$store.commit('v2/profile/SET_BANK_ACC_CHECK_PAYLOAD', val);
      },
    },
  },
  watch: {
    checkBankAccountDone(val) {
      if (val && this.bankAccountCheckPayload === this.listingUuid) {
        this.inputHandler();
      }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async inputHandler(_) {
      try {
        this.actionLoading = true;

        const canPublishListing = await this.$store.dispatch(
          'myListing/checkPublishListing',
          this.listingUuid,
        );
        // this.actionLoading=false;
        if (canPublishListing) {
          // this.actionLoading=true;
          let resultCheck = true;
          if (!this.checkBankAccountDone) {
            resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
          }
          // this.actionLoading=false;
          if (resultCheck == false) {
            this.$modal.show('modal-input-bank-account');
            this.bankAccountCheckPayload = this.listingUuid;
          } else {
            // this.actionLoading=true;
            const publishSuccess = await this.$store.dispatch(
              'myListing/publishListing',
              this.listingUuid,
            );
            // this.actionLoading=false;
            if (publishSuccess) {
              if (this.fromMylisting) {
                this.$store.dispatch('myListing/getListings', this.page);
                this.$store.dispatch('myListing/fetchSearchListingOptions');
                this.$store.dispatch('myListing/getCategoryCountData');
              }
              await this.$swal(
                this.$i18n.t('general.success'),
                this.$i18n.t('booking.swal.draft.successPublish'),
                'success',
              );
              this.$emit('actionDone');
            }
          }
        } else {
          this.$swal({
            title: this.$i18n.t('mylisting.failedPublishTitle'),
            text: this.$i18n.t('mylisting.failedPublishMessage'),
            type: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('mylisting.completeData'),
            allowOutsideClick: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({
                path: '/listing/edit/' + this.listingUuid,
              });
            }
          });
        }
      } finally {
        this.actionLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
