<template>
  <router-link :to="href" style="text-decoration: none;">
    <button class="btn btn-primary">
      {{ $t('general.btn.edit') }}
    </button>
  </router-link>
</template>

<script>
export default {
  name: 'edit-btn',
  props: ['listingUuid'],
  computed: {
    href() {
      let subUrl = this.page ? '?page=' + this.page : '';
      return `/listing/edit/${this.listingUuid + subUrl}`;
    },
  },
};
</script>

<style scoped></style>
