<template>
  <button class="btn btn-primary" @click="clickHandler">
    {{ $t('general.btn.duplicate') }}
  </button>
</template>

<script>
export default {
  name: 'duplicate-btn',
  props: ['listingUuid'],
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
  },
  methods: {
    async duplicate() {
      try {
        this.actionLoading = true;
        const newListingUuid = await this.$store.dispatch(
          'myListing/duplicateListing',
          this.listingUuid,
        );
        this.$swal({
          title: this.$i18n.t('general.success'),
          text: this.$i18n.t('myListing.modal.duplicate.success'),
          type: 'success',
          confirmButtonText: this.$i18n.t('general.btn.checkListing'),
          allowOutsideClick: false,
          // eslint-disable-next-line
        }).then(result => {
          let subUrl = this.page ? '?page=' + this.page : '';
          this.$router.push(`/listing/edit/${newListingUuid + subUrl}`);
        });
      } finally {
        this.actionLoading = false;
      }
    },
    clickHandler() {
      this.$swal({
        title: this.$i18n.t('myListing.modal.duplicate.title'),
        text: this.$i18n.t('myListing.modal.duplicate.message'),
        type: 'info',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          this.duplicate();
        }
      });
    },
  },
};
</script>

<style scoped></style>
