<template>
  <button class="btn btn-danger" @click="clickHandler">
    {{ $t('general.btn.delete') }}
  </button>
</template>

<script>
export default {
  name: 'delete-btn',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      default: null,
    },
    fromMylisting: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
  },
  methods: {
    async delete() {
      this.actionLoading = true;
      try {
        const deleteCheckResult = await this.$store.dispatch(
          'myListing/checkDeleteListing',
          this.listingUuid,
        );
        if (deleteCheckResult) {
          const success = await this.$store.dispatch('myListing/deleteListing', this.listingUuid);
          if (success) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
              this.$store.dispatch('myListing/getCategoryCountData');
            }
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t('myListing.modal.delete.success'),
              'success',
            );
            this.$emit('actionDone');
          }
        } else {
          this.$swal({
            title: this.$i18n.t('myListing.modal.delete.failedT'),
            text: this.$i18n.t('myListing.modal.delete.failedM'),
            type: 'error',
            // showCancelButton: true,
            confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('general.yes'),
            allowOutsideClick: false,
          });
        }
      } finally {
        this.actionLoading = false;
      }
    },
    clickHandler() {
      this.$swal({
        title: this.$i18n.t('myListing.modal.delete.title'),
        text: this.$i18n.t('myListing.modal.delete.message'),
        type: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          this.delete();
        }
      });
    },
  },
};
</script>

<style scoped></style>
