<template>
  <div class="active--switch">
    <label :for="`activationToggle${listingUuid}`" class="custom--switch">
      <input
        :id="`activationToggle${listingUuid}`"
        type="checkbox"
        :checked="activated"
        @click.prevent="inputHandler"
      />
      <span class="slider"></span>
    </label>
    <label :for="`activationToggle${listingUuid}`" class="switch--label">
      {{ activated ? $t('general.btn.active') : $t('general.btn.notActive') }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'activation-toggle',
  props: {
    activated: {
      type: Boolean,
      default: false,
    },
    listingUuid: {
      type: String,
      required: true,
    },
    checkBankAccountDone: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: null,
    },
    fromMylisting: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
    bankAccountCheckPayload: {
      get() {
        return this.$store.state.v2.profile.bankAccountCheckPayload;
      },
      set(val) {
        this.$store.commit('v2/profile/SET_BANK_ACC_CHECK_PAYLOAD', val);
      },
    },
  },
  watch: {
    checkBankAccountDone(val) {
      if (val && this.bankAccountCheckPayload === this.listingUuid) {
        this.inputHandler();
      }
    },
  },
  methods: {
    async activate() {
      try {
        this.actionLoading = true;
        let resultCheck = true;
        if (!this.checkBankAccountDone) {
          resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
        }
        // this.actionLoading=false;
        if (resultCheck == false) {
          this.$modal.show('modal-input-bank-account');
          this.bankAccountCheckPayload = this.listingUuid;
        } else {
          const success = await this.$store.dispatch('myListing/activateListing', this.listingUuid);
          if (success) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
              this.$store.dispatch('myListing/getCategoryCountData');
            }
            await this.$swal(
              this.$i18n.t('myListing.modal.activate.title'),
              this.$i18n.t('myListing.modal.activate.message'),
              'success',
            );
            this.$emit('actionDone');
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.actionLoading = false;
      }
    },
    async deactivate() {
      try {
        const result = await this.$swal({
          title: this.$i18n.t('myListing.modal.deactivate.title'),
          text: this.$i18n.t('myListing.modal.deactivate.message'),
          type: 'error',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          allowOutsideClick: false,
        });
        if (result.value) {
          this.actionLoading = true;
          const success = await this.$store.dispatch(
            'myListing/deactivateListing',
            this.listingUuid,
          );
          this.actionLoading = false;
          if (success) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
            }
            // this.$store.dispatch('myListing/getCategoryCountData');
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t('myListing.modal.deactivate.success'),
              'success',
            );
            this.$emit('actionDone');
          }
        }
      } finally {
        this.actionLoading = false;
      }
    },
    inputHandler() {
      if (this.activated) {
        this.deactivate();
      } else {
        this.activate();
      }
    },
  },
};
</script>

<style scoped></style>
