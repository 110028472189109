<template>
  <button class="btn btn-primary" @click="clickHandler">
    {{ $t('general.btn.repost') }}
  </button>
</template>

<script>
export default {
  name: 'delete-btn',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      default: null,
    },
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
  },
  methods: {
    async repost() {
      this.actionLoading = true;
      try {
        const success = await this.$store.dispatch('myListing/repostListing', this.listingUuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('myListing.modal.repost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } finally {
        this.actionLoading = false;
      }
    },
    clickHandler() {
      this.$swal({
        title: this.$i18n.t('myListing.modal.repost.title'),
        text: this.$i18n.t('myListing.modal.repost.message'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          this.repost();
        }
      });
    },
  },
};
</script>

<style scoped></style>
